<template>
  <footer>
    <img src="@/assets/Slordef_logo.png" alt="Souls Gathering Logo">
    <h3>Follow Souls Gathering</h3>
    <div>
      <a href="https://www.facebook.com/SoulsGathering"><i class="icon fab fa-facebook"></i></a>
      <a href="https://open.spotify.com/artist/04P4TKrzQczdL61sOg4bHt"><i class="icon fab fa-spotify"></i></a>
      <a href="https://www.deezer.com/fr/artist/7915520"><i class="icon fab fa-deezer"></i></a>
    </div>
    <h3>Follow Slordef</h3>
    <div>
      <a href="https://www.facebook.com/Slordefofficialpage/"><i class="icon fab fa-facebook"></i></a>
      <a href="https://twitter.com/slordef"><i class="icon fab fa-twitter"></i></a>
      <a href="https://discord.gg/xx5kuQj5Qh"><i class="icon fab fa-discord"></i></a>
      <a href="https://twitch.tv/slordef"><i class="icon fab fa-twitch"></i></a>
    </div>
  </footer>
  <div class="sign">
    &copy;2022 Copyright <a href="https://slordef-webmaster.ch">Slordef</a>, all right reserved
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
footer {
  padding: 40px;
  text-align: center;
  background-color: hsl(0, 100%, 5%);
  div {
    color: indianred;
    a {
      color: hsl(10, 100%, 40%);
      font-size: 2.5em;
      margin: 0 35px;
      &:hover {
        .icon {
          color: hsl(0, 100%, 30%);
        }
      }
    }
    a:not(:first-child) {
      position: relative;
      &:before {
        content: '●';
        font-size: 1.5rem;
        position: absolute;
        top: 13px;
        left: -38px;
        pointer-events: none;
      }
    }
  }
}
.sign {
  background-color: hsl(0, 100%, 5%);
  text-align: center;
  font-size: 0.6em;
  a {
    text-decoration: underline;
  }
}
@media (max-width: 800px) {
  footer div a {
    margin: 0 15px;
    &:before {
      display: none;
    }
  }
}
</style>
