import { RouteRecordRaw } from 'vue-router'
import Home from '@/components/Home.vue'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'Home' }
  }
]
