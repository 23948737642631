<template>
  <header>
    <nav>
      <a href="#discography">Discography</a>
      <a href="#about">About</a>
      <a href="#links">Links</a>
    </nav>
  </header>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
header {
  background-color: hsl(0, 100%, 5%);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
}
nav {
  display: flex;
  justify-content: space-between;
  a {
    margin: 20px;
  }
}
</style>
