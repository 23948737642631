<template>
  <section id="head">
    <h1>Souls Gathering</h1>
  </section>
  <section id="discography">
    <article class="disco">
      <img src="@/assets/discimage/image_album_deadly_sins_blanc.jpg" alt="Deadly Sins Album">
      <div>
        <h4>Deadly Sins</h4>
        <h5>2015</h5>
        <ul>
          <li>Vanity</li>
          <li>Greed</li>
          <li>Envy</li>
          <li>Wrath</li>
          <li>Lust</li>
          <li>Gluttony</li>
          <li>Sloth</li>
          <li>Suicide</li>
        </ul>
      </div>
    </article>
    <article class="disco">
      <img src="@/assets/discimage/image_album_set_your_senses.jpg" alt="Set Your Senses Album">
      <div>
        <h4>Set Your Senses</h4>
        <h5>2014</h5>
        <ul>
          <li>Take in Mind</li>
          <li>Earth</li>
          <li>I live in a Dream</li>
          <li>Set Your Senses</li>
          <li>Fight Against Me</li>
          <li>Lovely Girl</li>
          <li>Illusion</li>
          <li>This is the End</li>
        </ul>
      </div>
    </article>
    <article class="disco">
      <img src="@/assets/discimage/image_album_death_is_coming.jpg" alt="Death is Coming Album">
      <div>
        <h4>Death is Coming</h4>
        <h5>2010</h5>
        <ul>
          <li>The Gathering</li>
          <li>The Cry of Souls</li>
          <li>Beyond Source</li>
          <li>Ancestors</li>
          <li>World has Changed</li>
          <li>Death is Coming</li>
          <li>Death in his Hands</li>
        </ul>
      </div>
    </article>
  </section>
  <section id="about">
    <article class="about">
      <h2>About Souls Gathering</h2>
      <p>
        Souls Gathering is a solo project created in 2008 by Slordef in order to give a name and an image to the music he created in the Metal style.
        The "Souls Gathering" name comes from the idea that souls are watching us and tell our story.
        The texts of the songs may seem contradictory, but it is intended by the author Slordef. For him, play with these contradictions mean that we must go beyond the words and their definitions first. This is particularly true in the album "Set Your Senses" has real meaning for the senses "touch", "view", "hearing", "taste", "smell" which does not really coincide with the "meaning" of the words as expressed in the song "Set Your Senses" at first. The man not only communicates with speech, because gestures and behavior are even more important.
        Deadly Sins is the album retracing the seven deadly sins. Inspired by them, Slordef imagine telling the story of a personage through the various steps and finally dying under the weight of guilt.
      </p>
    </article>
    <article class="about">
      <h2>About Slordef</h2>
      <p>
        Slordef is a composer, author, interpreter, born in 1987 in Switzerland. It never ceases to evolve in the world of music, one area where it feels really good, having started in the composition from an early age.
        He followed the bands as "X-trême88" (group of young teenagers) where he was the lead singer and guitarist followed by "The Smokers" later renamed "Aenym" (Trash-Death Metal) always as a singer-guitarist and now works with the "Vaalnor" group as drummer in which he put his compositional style Pagan-Folk-Metal since 2009 that he is a founding member. In parallel to this, Slordef also composes music and melodies unrelated metal. Devoting his life to music and the creation Slordef does not have many resources or professional equipment to carry out its projects and is fully committed to achieve alone.
        In 2015, Slordef chosen to found a black metal band (Blackened Death), with singer Vaalnor on bass, named "Nebula Silva".
      </p>
    </article>
  </section>
  <section id="links">
    <a class="link" href="https://www.akaipro.com/" target="_blank"><img src="@/assets/akai_logo.jpg" alt="Akai Pro"></a>
    <a class="link" href="https://www.jacksonguitars.com/en/start" target="_blank"><img src="@/assets/629_mspJacksonGuitarsLogo.jpg" alt="Jackson Guitars"></a>
  </section>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
h1 {
  position: absolute;
  bottom: 10px;
  color: #8c1313;
  font-size: 2em;
}
section {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &#head {
    height: calc(100vh - 60px);
    background: url("~@/assets/Fond_pochette_Deadly_Sins.jpg") no-repeat;
    background-size: contain;
    background-position: center;
  }
}
article.about {
  text-align: center;
  max-width: 800px;
}
section#discography {
  flex-direction: row;
  flex-wrap: wrap;
}
article.disco {
  display: flex;
  margin: 25px;
  flex-wrap: wrap;
  img {
    padding: 10px;
    width: 100%;
    max-width: 300px;
    height: 100%;
  }
  div {
    width: 200px;
    margin-left: 25px;
    h4, h5 {
      margin: 0;
    }
    ul, li {
      list-style: none;
      margin: 0;
      padding: 0;
      line-height: 1.2em;
    }
    ul {
      margin-top: 20px;
    }
  }
}
section#links {
  flex-direction: row;
  flex-wrap: wrap;
}
.link {
  margin: 15px;
}
</style>
